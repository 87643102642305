<template>
  <!-- begin::kb-main -->
  <main class="kb-main main-center">
    <div class="" :class="isMobile ? 'main-content' : 'min-component'">
      <div class="error-page">
        <div class="images">
          <img src="../../../assets/lxp/images/common/img_empty.png" />
        </div>
        <h3 class="title">페이지를 찾을 수 없습니다.</h3>
        <p class="description">원하시는 결과를 찾을 수 없습니다. 올바른 URL을 <br/>입력하였는지 확인하세요. </p>
        <div class="buttons">
          <router-link :to="{name: 'Main'}" class="kb-btn kb-btn-primary" :class="isMobile ? 'kb-btn-content-item' : 'kb-btn-xl'"><span class="text">메인으로 돌아가기</span></router-link>
        </div>
      </div>
    </div>
  </main>
  <!-- end::kb-main -->
</template>

<script>
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "PageNotFound",
  setup() {
    return {
      isMobile: navigationUtils.any()
    }
  }
}
</script>
